const primaryColor = "#5d0039"
const secondaryColor = "#A9667D"
const accentColor = "#120000"

const appTitle =
  "Go Touchless | Flatware & Chopsticks of Innovative Elegance that never touch the table"
const metaDescription =
  "Touchless Flatware is unique product that when upright the mouthpiece end never touches the table."
const metaKeywords = "leads, networking, virtual networking"

const googleFonts =
  "https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap"

const fontFamilies = "'Domine', serif,'Quicksand', sans-serif"
const headlineFont = "'Domine', serif"
const bodyFont = "'Quicksand', sans-serif"

const headlineFontStyles = {
  fontFamily: headlineFont,
  fontWeight: 800,
  fontStyle: "normal",
  letterSpacing: "-0.02em",
}

const commonColorStyles = {
  white: "#fff",
  black: "#000",
  grey: "#696a6c",
  lightGrey: "#fafafa",
}

const customColorStyles = {
  goGreen: {
    main: "#7ca260",
  },
}

export {
  googleFonts,
  primaryColor,
  secondaryColor,
  accentColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  metaDescription,
  metaKeywords,
  appTitle,
  customColorStyles,
}
